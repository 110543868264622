import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import BtnWrap from "../components/btn-wrap"
import ImgCon from "../components/img-con"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály geografie, GIS a další" />
    <h1>Studijní materiály geografie, GIS a další</h1>
    <p>Na webu jsem pro vás připravila zápisky a výpisky, které byly pořízeny zejména při mém studiu na Masarykově univerzitě v Brně. Ty byly poté upraveny do elektronické formy a v mnoha případech doprovozeny i nově vytvořenými obrázky. Snad vám pomohou při studiu!
    Všechny zápisky a poznámky jsou dostupné zdarma a on-line.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon>
    <StaticImage
      src="../images/globus.jpg"
      alt="Studijní materiály zdarma - geografie"
      placeholder="dominantColor"
      layout="constrained"
      width={600}
      height={600}
      formats={["auto", "webp", "avif"]}
    />
    <div><h2>Co je vlastně geografie? Co vše zahrnuje?</h2>
    <p><strong>Geografie</strong> (či <strong>zeměpis</strong>) hledala hlavně v minulosti odpovědi na otázky co a kde leží. S koncem velkých zámořských objevů a zmizením bílých míst na mapě se postupně zaměřila na další, sofistikovanější otázky. Proč to tam leží, jak to ovlivňuje okolí a životy lidí, co se s tím dá dělat?</p>
    </div></ImgCon>
    <hr />
    <h2>Abecední seznam dostupných studijních materiálů</h2>
    <ul>
      <li><Link to="/biogeografie/">Biogeografie</Link></li>
      <li><Link to="/ekologie-zivotni-prostredi/">Ekologie a životní prostředí</Link></li>
      <li><Link to="/hydrologie/">Hydrologie a hydrogeografie</Link></li>
      <li><Link to="/gis/mapova-kompozice/">Mapová kompozice</Link></li>
      <li><Link to="/zaklady-geodezie-gps/">Základy geodézie a GPS</Link></li>
    </ul>
  </Layout>
)
